import { memo, useCallback, useContext } from "react";
import "./SmartDPHotelDescription.scss";
import loadable from "@loadable/component";
import AppGlobalsContext from "app/AppGlobalsContext";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import ProductNameWithCategory from "app/pages/.shared/ProductNameWithCategory/ProductNameWithCategory";
import get from "lodash/get";
import PropTypes from "prop-types";
import Collapse from "rc-collapse";
import { FormattedMessage } from "react-intl";
import SmartDPPatchwork from "app/pages/SmartDP/Quote/SmartDPPatchwork";
import { sendTagOnOpenProductCarouselDesktop } from "app/utils/analytics";
import {
	ALERT_TYPE,
	DESTINATION_USA_CODE,
	PRODUCT_PAGE_CODE_STATUS,
	SLIDE_SHOW_PHOTO_TYPES,
} from "app/constants";
import LineClampViewMore from "app/pages/.shared/LineClampViewMore/LineClampViewMore";
import SimpleProgressiveImg from "app/pages/.shared/SimpleProgressiveImg";
import NegociatedList from "app/pages/FicheProduit/NegociatedList/NegociatedList";
import List from "app/pages/.shared/List";
import UsefulInformation from "app/pages/FicheProduit/components/UsefulInformation/UsefulInformation";
import SmartDPHotelDescriptionLoader from "app/pages/SmartDP/Quote/HotelDescription/SmartDPHotelDescriptionLoader";
import Badge from "app/pages/.shared/Badge";
import Timer from "app/pages/.shared/Timer";
import AdditionalSections from "app/pages/FicheProduit/components/AdditionalSections/AdditionalSections";
import { useAnalytics } from "app/utils/analytics/useAnalytics";
import { htmlSanitize } from "app/utils/sanitize";
import ProductRatingContainer from "app/pages/FicheProduit/components/ProductRating/ProductRatingContainer";
import { isFlashsaleProduct } from "app/utils/utils";
import AlertMessage from "app/pages/.shared/AlertMessage";
import className from "classnames";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";
import { isEmpty } from "lodash";
import Paragraphs from "app/pages/.shared/Paragraphs";

const PhotoCarousel = loadable(() => import("app/pages/.shared/PhotoCarousel.jsx"));

const FacilitySection = loadable(() => import("./FacilitySection"));

const LocationSection = loadable(() => import("./LocationSection"));

const SlideShow = loadable(() => import("app/pages/.shared/Slideshow/SlideShow.jsx"));

const PHOTO_PRINT_IMG_SIZES = {
	small: { width: 800 },
	medium: { width: 800 },
	large: { width: 800 },
	xlarge: { width: 800 },
};

const SmartDPHotelDescription = ({
	hotel = {},
	slideshow = {},
	closeSlideshow = () => {},
	openSlideshow = () => {},
	printMode,
	specialOfferInfos = {},
	googleAPIKey,
	showTimer,
}) => {
	const { resolution } = useContext(AppGlobalsContext);

	const { track } = useAnalytics();

	const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;

	const tripAdvisorData = hotel.tripadvisor || {};
	const holidayCheckData = hotel.holidaycheck || {};

	const openPhotosSlideshow = useCallback(() => {
		sendTagOnOpenProductCarouselDesktop();
		openSlideshow(SLIDE_SHOW_PHOTO_TYPES.PHOTOS);
	}, []);

	const handleProductDetailsExpand = useCallback(event => {
		const label = event.target.textContent;

		track("expand_product_details", {
			detailsTitle: label,
		});
	}, []);

	const showTimerOnProductPicture =
		showTimer &&
		isFlashsaleProduct(hotel) &&
		(hotel.expiration?.hour > 0 || hotel.expiration?.day > 0);
	const hotelName = <ProductNameWithCategory category={hotel.category} name={hotel.name} />;

	const warningMessage =
		hotel?.geography?.levels
			?.map(level => level?.code)
			.some(code => DESTINATION_USA_CODE.includes(code)) || false;

	const hotelDesc =
		hotel.status === PRODUCT_PAGE_CODE_STATUS.LOADING ? (
			<div className="sdp-hotel-description">
				<SmartDPHotelDescriptionLoader />
			</div>
		) : (
			<div className="sdp-hotel-description">
				<div className="sdp-hotel-description__photo">
					{isMobile && !printMode && get(hotel, "photos.length") > 0 && (
						<PhotoCarousel photos={hotel.photos} resolution={resolution} />
					)}
					{!isMobile && !printMode && slideshow.isSlideshowOpened && (
						<SlideShow
							isOpened={slideshow.isSlideshowOpened}
							close={closeSlideshow}
							photos={hotel.photos}
						/>
					)}
					{showTimerOnProductPicture && (
						<div className="sdp-hotel-description__badge">
							<Badge>
								<Timer
									hour={hotel.expiration?.hour}
									day={hotel.expiration?.day}
									suffix={<FormattedMessage id="timer.left.label" />}
								/>
							</Badge>
						</div>
					)}
				</div>
				<div className="sdp-hotel-description__body">
					<div className="sdp-hotel-description__header">
						<div>
							<Typography
								variant={TYPOGRAPHY_VARIANTS.XL2}
								isBold
								className="sdp-hotel-description__name"
								component="div"
							>
								{hotelName}
							</Typography>

							<div className="sdp-hotel-description__location">{hotel.location}</div>
						</div>

						<ProductRatingContainer
							locationName={hotelName}
							tripAdvisor={{
								...tripAdvisorData,
								reviewsCount: tripAdvisorData.num_reviews,
							}}
							holidayCheck={{
								...holidayCheckData,
								size: "large",
								isCompact: true,
							}}
						/>
					</div>

					{!isMobile && get(hotel, "photos.length") > 0 && !printMode && (
						<SmartDPPatchwork photos={hotel.photos} onShowMore={openPhotosSlideshow} />
					)}

					{printMode && get(hotel, "photos.length") > 0 && (
						<SimpleProgressiveImg
							src={hotel.photos[0].url}
							resolution={resolution}
							sizes={PHOTO_PRINT_IMG_SIZES}
						/>
					)}

					{printMode ? (
						<div
							className="sdp-hotel-description__text"
							dangerouslySetInnerHTML={{ __html: htmlSanitize(hotel.summary) }}
						/>
					) : (
						<div className="sdp-hotel-description__text">
							<LineClampViewMore
								enableShowMore={true}
								lineCount={isMobile ? 6 : 5}
								text={
									<span
										dangerouslySetInnerHTML={{
											__html: htmlSanitize(hotel.summary),
										}}
									/>
								}
							/>
						</div>
					)}
				</div>

				{printMode ? (
					<>
						{hotel.sections?.primary?.map(section => {
							return (
								<AdditionalSections
									headline={section.headline}
									blocks={section.blocks}
									moreDetails={section.moreDetails}
								/>
							);
						})}
						{hotel.facilityGroups?.length > 0 && (
							<FacilitySection facilities={hotel.facilityGroups} />
						)}
						<LocationSection hotel={hotel} googleAPIKey={googleAPIKey} />

						{hotel.sections?.secondary?.map(section => {
							return (
								<AdditionalSections
									headline={section.headline}
									blocks={section.blocks}
									moreDetails={section.moreDetails}
								/>
							);
						})}

						<NegociatedList
							negociatedList={hotel.negotiated}
							specialOffer={specialOfferInfos}
						/>
						<div className="sdp-hotel-description__included">
							<div className="included">
								<div className="included__list">
									<div className="included__title">
										<FormattedMessage id="price.detail.included.title">
											{msg => <span>{`${msg} :`}</span>}
										</FormattedMessage>
									</div>
									<List
										items={get(hotel, `included`)}
										enableHTMLFormatting={true}
									/>
								</div>
								<div className="included__list">
									<div className="included__title">
										<FormattedMessage id="price.detail.not.included.title">
											{msg => <span>{`${msg} :`}</span>}
										</FormattedMessage>
									</div>
									<List
										items={get(hotel, `notIncluded`)}
										enableHTMLFormatting={true}
									/>
								</div>
							</div>
						</div>
						<UsefulInformation usefulInformations={hotel.usefulInformations} />

						{warningMessage && (
							<AlertMessage
								alertType={ALERT_TYPE.INFO}
								className="warning-usa-destination"
								message={
									<FormattedMessage id="general.warning.adult.destination.usa" />
								}
							/>
						)}
					</>
				) : (
					<Collapse
						className={className({
							"sdp-hotel-description__sections": true,
							"sdp-hotel-description__sections--with-warning": warningMessage,
						})}
						expandIcon={({ isActive }) => {
							return (
								<i
									style={{
										transition: "transform .2s ease",
										transform: `rotate(${isActive ? 180 : 0}deg)`,
									}}
									className="icon icon--arrow-down-grey"
								/>
							);
						}}
					>
						{hotel.sections?.primary?.map(section => {
							return (
								<Collapse.Panel
									className="sdp-hotel-description__sections-item"
									panelKey={section.headline}
									headerClass="sdp-hotel-description-item__header"
									header={
										<Typography
											variant={TYPOGRAPHY_VARIANTS.LARGE}
											isBold
											className="sdp-hotel-description-item__title"
											onClick={handleProductDetailsExpand}
										>
											{section.headline}
										</Typography>
									}
								>
									<div className="sdp-hotel-description-item__body">
										<AdditionalSections
											blocks={section.blocks}
											moreDetails={section.moreDetails}
										/>
									</div>
								</Collapse.Panel>
							);
						})}
						{hotel.facilityGroups?.length > 0 && (
							<Collapse.Panel
								className="sdp-hotel-description__sections-item"
								headerClass="sdp-hotel-description-item__header"
								header={
									<Typography
										variant={TYPOGRAPHY_VARIANTS.LARGE}
										isBold
										className="sdp-hotel-description-item__title"
										onClick={handleProductDetailsExpand}
									>
										<FormattedMessage id="sdp.hotel.description.facilities.label" />
									</Typography>
								}
							>
								<div className="sdp-hotel-description-item__body">
									<FacilitySection facilities={hotel.facilityGroups} />
								</div>
							</Collapse.Panel>
						)}
						<Collapse.Panel
							className="sdp-hotel-description__sections-item"
							headerClass="sdp-hotel-description-item__header"
							header={
								<Typography
									variant={TYPOGRAPHY_VARIANTS.LARGE}
									isBold
									className="sdp-hotel-description-item__title"
									onClick={handleProductDetailsExpand}
								>
									<FormattedMessage id="sdp.hotel.description.location.label" />
								</Typography>
							}
						>
							<div className="sdp-hotel-description-item__body">
								<LocationSection hotel={hotel} googleAPIKey={googleAPIKey} />
							</div>
						</Collapse.Panel>
						{hotel.sections?.secondary?.map(section => {
							return (
								<Collapse.Panel
									className="sdp-hotel-description__sections-item"
									panelKey={section.headline}
									headerClass="sdp-hotel-description-item__header"
									header={
										<Typography
											variant={TYPOGRAPHY_VARIANTS.LARGE}
											isBold
											className="sdp-hotel-description-item__title"
											onClick={handleProductDetailsExpand}
										>
											{section.headline}
										</Typography>
									}
								>
									<div className="sdp-hotel-description-item__body">
										<AdditionalSections
											blocks={section.blocks}
											moreDetails={section.moreDetails}
										/>
									</div>
								</Collapse.Panel>
							);
						})}
						{!isEmpty(hotel.destination?.importantInfo) && (
							<Collapse.Panel
								className="sdp-hotel-description__sections-item"
								panelKey={"importantInfomation"}
								headerClass="sdp-hotel-description-item__header"
								header={
									<>
										<Typography
											variant={TYPOGRAPHY_VARIANTS.LARGE}
											isBold
											className="sdp-hotel-description-item__title"
											onClick={handleProductDetailsExpand}
										>
											<FormattedMessage id="quotation.destination.important.information.title" />
										</Typography>
									</>
								}
							>
								<div className="sdp-hotel-description-item__body">
									<Paragraphs
										paragraphs={hotel?.destination?.importantInfo}
										enableHTMLFormatting
									/>
								</div>
							</Collapse.Panel>
						)}

						{warningMessage && (
							<AlertMessage
								alertType={ALERT_TYPE.INFO}
								className="warning-usa-destination"
								message={
									<FormattedMessage id="general.warning.adult.destination.usa" />
								}
							/>
						)}
					</Collapse>
				)}
			</div>
		);

	return hotelDesc;
};

SmartDPHotelDescription.propTypes = {
	printMode: PropTypes.bool,
	hasSdpSpecialOffer: PropTypes.bool,
	specialOfferInfos: PropTypes.object,
	hotel: PropTypes.object,
	slideshow: PropTypes.shape({
		isSlideshowOpened: PropTypes.bool,
		photosType: PropTypes.string,
	}),
	closeSlideshow: PropTypes.func,
	openSlideshow: PropTypes.func,
	googleAPIKey: PropTypes.string,
	showTimer: PropTypes.bool,
};

export default memo(SmartDPHotelDescription);
